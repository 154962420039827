import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, FormField, Input, Label, Select, Spinner, RadioButton } from 'cj-common-components';
import { Field } from 'formik';
import { isEmpty } from '@turbopay/ts-helpers/object-utils';
import classNames from 'classnames';
import DurationInput from './DurationPicker';
import { getConfigSection } from '../../common/utils';
import { ModalError } from './ModalWindow';
import MultiSelect from './MultiSelect';
import SimpleFormGroup from './SimpleFormGroup';
import CustomFormGroup from './CustomFormGroup';
import ValidationFieldErrorMessage from './ValidationFieldErrorMessage';
import { DATA_STATUS } from '../../common/constants';
import { RequiredFieldSymbol } from '../common-components/RequiredFieldSymbol';
import './BaseFormFields.css';

const config = require('../../resources/config.json');

const uiTexts = require('../../resources/uiTexts.json');

const STATUS_LOADING = 'STATUS_LOADING';
const STATUS_ERROR = 'STATUS_ERROR';
const STATUS_LOADED = 'STATUS_LOADED';

const icons = getConfigSection(config, 'ui.common.table.icons');

const disabledStyle = {
  borderColor: '#d8d8d8',
  backgroundColor: '#f2f2f2',
  color: '#a8adb3',
  cursor: 'default',
  pointerEvents: 'none',
};

export default class BaseFormFields extends React.PureComponent {
  static propTypes = {
    fieldsTextKey: PropTypes.string.isRequired,
    // Imposible to define a common type for this parameter
    // eslint-disable-next-line react/forbid-prop-types
    fieldsRenderConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
    refDataLoadFunctions: PropTypes.arrayOf(PropTypes.func),
    refDataLoadCallback: PropTypes.func,
    dataStatusLoadCallback: PropTypes.func,
    testIdPrefix: PropTypes.string,
  };

  static defaultProps = {
    refDataLoadFunctions: [],
    dataStatusLoadCallback: () => {
      // eslint-disable-next-line no-console
      console.warn('You can implement dataStatusLoadCallback for component which invoke BaseFormFields');
    },
  };

  static renderCommonFramework = ({
    id,
    label,
    renderFieldComponent,
    isNotRenderValidation = false,
    hasNoLabel = false,
    customClass,
    useRequiredLabel,
    testIdPrefix,
  }) => {
    const labelTestId = testIdPrefix ? `${testIdPrefix}-${id}-label` : undefined;
    const errorMessageTestId = testIdPrefix ? `${testIdPrefix}-${id}-error-message` : undefined;

    return (
      <>
        <Field
          name={id}
          key={id}
          // form and ...props left but commented for informational purposes
          // Disable lint error since if we implement the suggested change
          // then, for any strange reason, we lose focus from field after each
          // keystroke
          // eslint-disable-next-line react/no-children-prop
          children={({ field, form /*...props */ }) => {
            const isRequired =
              typeof useRequiredLabel === 'function' ? useRequiredLabel(form.values) : useRequiredLabel;

            return (
              <FormField
                className={customClass}
                label={labelProps =>
                  hasNoLabel ? (
                    <></>
                  ) : (
                    <Label {...labelProps} testId={labelTestId}>
                      {isRequired ? <RequiredFieldSymbol /> : ''}
                      {label}
                    </Label>
                  )
                }
              >
                {// Formik automatically injects fieldProps in the
                // result of the call to renderFieldComponent(field)
                () => renderFieldComponent({ ...field, testIdPrefix })}
              </FormField>
            );
          }}
        />
        {isNotRenderValidation ? <></> : <ValidationFieldErrorMessage fieldName={id} testId={errorMessageTestId} />}
      </>
    );
  };

  static renderBinListTable(inputItems, onSortEnd, addNewLine, deleteRow, enabled, headers, name, disabledAddButton) {
    const gridStyle = {
      display: 'grid',
      gridTemplateColumns: '50px 1fr 1fr',
      gridAutoRows: '80px',
    };

    const headerStyle = {
      display: 'grid',
      gridTemplateColumns: '1fr',
    };

    return props => {
      const { testIdPrefix, ...restProps } = props;
      const testIdListTable = testIdPrefix ? `${testIdPrefix}-list-table` : undefined;
      const testIdItem = testIdPrefix ? `${testIdPrefix}-list-table-item` : undefined;
      const testIdColumn = testIdPrefix ? `${testIdPrefix}-list-table-column` : undefined;
      const errorMessageTestId = testIdPrefix ? `${testIdPrefix}-list-table-error-message` : undefined;

      return (
        <div
          {...restProps}
          style={enabled ? headerStyle : { ...headerStyle, ...disabledStyle }}
          data-testid={testIdListTable}
        >
          <div style={gridStyle} className="o-inline-group">
            {headers.map(value => (
              <div key={value} className="o-inline-group__item" data-testid={testIdColumn}>
                {value}
              </div>
            ))}
          </div>
          {inputItems.map((item, index) => (
            <div key={item.id} className="o-inline-group" style={gridStyle} data-testid={testIdItem}>
              <div className="o-inline-group__item" style={{ paddingTop: 5 }}>
                <Button
                  {...{
                    className: 'c-table__btn--border',
                    secondary: true,
                    small: true,
                    icon: icons.deleteDataItem,
                    onClick: e => {
                      deleteRow(e, item.id);
                    },
                  }}
                  testId="delete-button"
                />
              </div>
              <div className="o-inline-group__item">
                {BaseFormFields.renderGenericInput(
                  'text',
                  enabled,
                )({
                  name: `${name}[${index}].regularExpression`,
                  value: item.regularExpression,
                  testIdPrefix,
                })}
              </div>
              <div className="o-inline-group__item">
                {BaseFormFields.renderGenericInput(
                  'text',
                  enabled,
                )({
                  name: `${name}[${index}].comment`,
                  value: item.comment,
                  testIdPrefix,
                })}
              </div>
            </div>
          ))}
          <div className="o-inline-group" style={{ ...gridStyle, gridAutoRows: 40 }}>
            <div className="o-inline-group__item">
              <Button
                {...{
                  className: 'c-table__btn--border',
                  secondary: true,
                  small: true,
                  icon: icons.addDataItem,
                  onClick: e => {
                    addNewLine(e);
                  },
                  disabled: disabledAddButton,
                  style: {
                    opacity: disabledAddButton ? '0.5' : 1,
                  },
                  testId: 'add-item-button',
                }}
              />
            </div>
            <div className="o-inline-group__item" style={{ paddingTop: 5 }}>
              <ValidationFieldErrorMessage fieldName={name} testId={errorMessageTestId} />
            </div>
          </div>
        </div>
      );
    };
  }

  static renderDurationTable(
    inputItems,
    onSortEnd,
    addNewLine,
    deleteRow,
    handleChange,
    handleBlur,
    enabled,
    headers,
    name,
    disabledAddButton,
  ) {
    const SortableItem = ({ value, currentRetry, id, testIdPrefix }) => {
      const testIdItem = testIdPrefix ? `${testIdPrefix}-duration-table-item` : undefined;
      const retryCellTestId = testIdPrefix ? `${testIdPrefix}-retry-cell` : undefined;
      const durationCellTestId = testIdPrefix ? `${testIdPrefix}-duration-cell` : undefined;

      return (
        <div className="o-inline-group sortable-table" data-testid={testIdItem}>
          <div className="o-inline-group__item" style={{ marginTop: '32px' }}>
            <Button
              {...{
                className: 'c-table__btn--border',
                secondary: true,
                small: true,
                icon: icons.deleteDataItem,
                onClick: e => {
                  deleteRow(e, id);
                },
                testId: 'delete-item-button',
              }}
            />
          </div>
          <div
            className="o-inline-group__item"
            style={{ paddingRight: 50, paddingTop: 20 }}
            data-testid={retryCellTestId}
          >
            {currentRetry}
          </div>
          <div className="o-inline-group__item" data-testid={durationCellTestId}>
            <DurationInput
              hoursPadding={3}
              value={value}
              maxValue={3599999}
              onChange={(e, newDuration) => {
                handleChange(newDuration, id);
              }}
              onBlur={handleBlur}
              enabled={enabled}
              testIdPrefix={testIdPrefix}
            />
          </div>
        </div>
      );
    };

    const SortableList = ({ items, testIdPrefix }) => {
      const testIdColumn = testIdPrefix ? `${testIdPrefix}-duration-table-column` : undefined;
      const errorMessageTestId = testIdPrefix ? `${testIdPrefix}-list-duration-error-message` : undefined;

      return (
        <div style={enabled ? {} : disabledStyle}>
          <div className="o-inline-group">
            {headers.map(value => (
              <div key={value} className="o-inline-group__item" data-testid={testIdColumn}>
                {value}
              </div>
            ))}
          </div>
          {items.map((item, index) => (
            <SortableItem
              key={item.id}
              index={index}
              value={item.intervalBetweenRetriesSec}
              currentRetry={index + 1}
              id={item.id}
              testIdPrefix={testIdPrefix}
            />
          ))}
          <div className="o-inline-group">
            <div className="o-inline-group__item">
              <Button
                {...{
                  className: 'c-table__btn--border',
                  secondary: true,
                  small: true,
                  icon: icons.addDataItem,
                  onClick: e => {
                    addNewLine(e);
                  },
                  disabled: disabledAddButton,
                  style: {
                    opacity: disabledAddButton ? '0.5' : 1,
                  },
                  testId: 'add-item-button',
                }}
              />
            </div>
          </div>
          <div className="o-inline-group">
            <div className="o-inline-group__item">
              <ValidationFieldErrorMessage fieldName={name} testId={errorMessageTestId} />
            </div>
          </div>
        </div>
      );
    };

    return props => <SortableList {...props} items={inputItems} onSortEnd={onSortEnd} />;
  }

  static renderGenericInput(type, enabled) {
    return props => {
      const { value, name, testIdPrefix } = props;
      const testId = testIdPrefix ? `${testIdPrefix}-${name}-input` : undefined;

      if (typeof value === 'number') {
        // eslint-disable-next-line no-param-reassign
        props.value = value.toString();
      }
      return <Input {...props} type={type} disabled={!enabled} testId={testId} />;
    };
  }

  static renderRadioButtonGroup(items, enabled, formikProps, isInline = true) {
    return props => {
      const { name, testIdPrefix } = props;
      const { setFieldValue } = formikProps;

      return items.map(item => {
        const testId = testIdPrefix ? `${testIdPrefix}-${item.value}-radio-button` : undefined;

        return (
          <RadioButton
            className={isInline ? '' : 'radio-button-new-line'}
            value={item.value}
            key={item.value}
            name={name}
            {...props}
            defaultChecked={item.checked}
            disabled={!enabled}
            onChange={() => {
              setFieldValue(name, item.value);
            }}
            testId={testId}
          >
            {item.display}
          </RadioButton>
        );
      });
    };
  }

  static renderGenericDurationPicker(enabled) {
    return props => {
      const componentInputClass = classNames({
        'is-error  ': props.isError,
      }).trim();
      return (
        <DurationInput
          id={props.id}
          value={props.value}
          name={props.name}
          maxValue={props.maxValue || 86399}
          minValue={props.minValue || 0}
          className={componentInputClass}
          noButtons
          enabled={enabled}
          {...props}
        />
      );
    };
  }

  static renderGenericButton(enabled, title, clickHandler) {
    return props => {
      const { testIdPrefix, ...restProps } = props;
      const testId = testIdPrefix ? `${testIdPrefix}-${restProps.name}-button` : undefined;

      return (
        <Button {...restProps} secondary type="button" disabled={!enabled} onClick={clickHandler} testId={testId}>
          {title}
        </Button>
      );
    };
  }

  static renderGenericUpload(enabled, changeHandler) {
    return props => {
      const { testIdPrefix, ...restProps } = props;
      const testId = testIdPrefix ? `${testIdPrefix}-${restProps.name}-button` : undefined;

      return (
        <input
          className="generic-upload"
          {...restProps}
          type="file"
          disabled={!enabled}
          onChange={changeHandler}
          data-testid={testId}
        />
      );
    };
  }

  static renderWarningUpload(formikProps, enabled, showWarning, setState, ref, url, text) {
    return props => {
      const { setFieldValue } = formikProps;
      const { testIdPrefix, ...restProps } = props;
      const testId = testIdPrefix ? `${testIdPrefix}-${restProps.name}-button` : undefined;
      const linkTestId = testIdPrefix ? `${testIdPrefix}-${restProps.name}-link` : undefined;

      return (
        <div className="warning-upload-wrapper">
          <input
            {...restProps}
            type="file"
            disabled={!enabled}
            ref={ref}
            value={undefined}
            onChange={event => {
              setFieldValue(props.name, event.target.files[0]);
            }}
            onClick={event => {
              if (!showWarning) {
                event.preventDefault();
              }

              if (enabled) {
                setState({ paymentOptionLogo: true, showWarning: true });
              }
            }}
            data-testid={testId}
          />
          {url && (
            <a href={url} data-testid={linkTestId}>
              {text}
            </a>
          )}
        </div>
      );
    };
  }

  static renderUpload(formikProps, enabled, ref) {
    return props => {
      const { setFieldValue } = formikProps;
      const { testIdPrefix, ...restProps } = props;
      const testId = testIdPrefix ? `${testIdPrefix}-${restProps.name}-button` : undefined;

      return (
        <input
          {...restProps}
          type="file"
          disabled={!enabled}
          ref={ref}
          value={undefined}
          onChange={event => {
            setFieldValue(props.name, event.target.files[0]);
          }}
          data-testid={testId}
        />
      );
    };
  }

  static renderGenericInputTime(enabled, step) {
    return props => {
      const componentInputClass = classNames({
        'c-input__input  ': true,
        'is-error  ': props.isError,
      }).trim();
      return React.createElement('input', {
        'data-testid': props.testId,
        className: componentInputClass,
        type: 'time',
        step,
        max: '23:59:59',
        id: props.id,
        value: props.value,
        placeholder: props.placeholder,
        name: props.name,
        disabled: !enabled,
        readOnly: props.readOnly ? 'readonly' : null,
        pattern: props.pattern ? props.pattern : null,
        title: props.title ? props.title : null,
        onChange: props.onChange,
        onBlur: props.onBlur,
        'aria-labelledby': props.ariaLabelledby,
      });
    };
  }

  static renderGenericSelect(enabled, values, restProps = {}) {
    return props => {
      const { name, testIdPrefix } = props;
      const testId = testIdPrefix ? `${testIdPrefix}-${name}-select` : undefined;

      return <Select {...props} disabled={!enabled} values={values} {...restProps} testId={testId} />;
    };
  }

  static renderGenericMultiSelect(enabled, placeholder, values, formikProps) {
    return props => {
      const { name, testIdPrefix } = props;
      const { setFieldValue } = formikProps;

      return (
        <MultiSelect
          key={name}
          {...props}
          isDisabled={!enabled}
          placeholder={placeholder}
          values={values}
          onChange={changeProps => {
            setFieldValue(name, changeProps);
          }}
          initialSelectedValues={props.value}
          testIdPrefix={testIdPrefix}
        />
      );
    };
  }

  static renderGenericCheckbox(enabled, childText, restProps = {}) {
    return props => {
      const checked = props.value || false;
      const testId = props.testIdPrefix ? `${props.testIdPrefix}-${props.name}-checkbox` : undefined;

      return (
        <Checkbox {...props} disabled={!enabled} {...restProps} checked={checked} testId={testId}>
          {childText}
        </Checkbox>
      );
    };
  }

  static renderGenericCheckboxGroup(keyText, dataItems, fieldName, enabled, handleChange, disableSpecificCheckboxes) {
    return fieldProps => {
      return (
        <div className="o-inline-group u-mb-small">
          {dataItems.map(dataItem => {
            const value = dataItem.value || dataItem;
            const display = dataItem.display || value;
            let isCheckboxEnabled;

            if (disableSpecificCheckboxes) {
              isCheckboxEnabled = !disableSpecificCheckboxes.includes(value);
            }

            // At initialization we get an array with the values selected
            // in the checkboxes. When we click on any of them, then this
            // function is called again with a true/false as value
            // Therefore we need to adapt the fieldProps object depending
            // on the input
            const filteredFieldProps = Array.isArray(fieldProps.value)
              ? {
                  ...fieldProps,
                  value: fieldProps.value.some(item => item[fieldName] === value),
                }
              : fieldProps;
            const checked = Array.isArray(fieldProps.value)
              ? fieldProps.value.some(item => item[fieldName] === value)
              : fieldProps.value[fieldName];

            const { renderGenericCheckbox } = BaseFormFields;
            return renderGenericCheckbox(isCheckboxEnabled ?? enabled, display, {
              key: `${keyText}-${String(value).trim()}`,
              className: 'o-inline-group__item',
              onChange: handleChange(value),
              checked,
            })(filteredFieldProps);
          })}
        </div>
      );
    };
  }

  static renderGenericTextarea(enabled, rows, cols, placeholder) {
    return props => {
      const { name, testIdPrefix } = props;
      const testId = testIdPrefix ? `${testIdPrefix}-${name}-textarea` : undefined;

      return (
        <Input
          {...props}
          textarea
          disabled={!enabled}
          rows={rows}
          cols={cols}
          placeholder={placeholder}
          testId={testId}
        />
      );
    };
  }

  constructor(props) {
    super(props);
    const { refDataLoadFunctions } = this.props;
    this.state = {
      loadStatus: isEmpty(refDataLoadFunctions) ? STATUS_LOADED : STATUS_LOADING,
    };
  }

  componentDidMount() {
    const { refDataLoadFunctions, refDataLoadCallback, dataStatusLoadCallback } = this.props;
    if (isEmpty(refDataLoadFunctions) || refDataLoadCallback === undefined) {
      return;
    }

    if (refDataLoadFunctions && refDataLoadFunctions.length > 0) {
      dataStatusLoadCallback({ status: DATA_STATUS.DATA_LOADING });
    }

    Promise.all(refDataLoadFunctions.map(func => func()))
      .then(values => {
        this.setState({ loadStatus: STATUS_LOADED });
        refDataLoadCallback(values);
        dataStatusLoadCallback({ status: DATA_STATUS.DATA_LOADED });
      })
      .catch(() => {
        this.setState({ loadStatus: STATUS_ERROR });
        dataStatusLoadCallback({ status: DATA_STATUS.DATA_ERROR });
      });
  }

  render() {
    const { fieldsTextKey, fieldsRenderConfig, testIdPrefix } = this.props;
    const texts = this.props.uiTexts || uiTexts;
    const fieldsTexts = getConfigSection(texts, fieldsTextKey);
    const { loadStatus } = this.state;

    return (
      <>
        {loadStatus === STATUS_LOADING && (
          <Spinner section small={false}>
            {this.renderFields(fieldsRenderConfig, fieldsTexts, testIdPrefix)}
          </Spinner>
        )}
        {loadStatus === STATUS_LOADED && this.renderFields(fieldsRenderConfig, fieldsTexts, testIdPrefix)}
        {loadStatus === STATUS_ERROR && (
          <>
            {this.renderFields(fieldsRenderConfig, fieldsTexts, testIdPrefix)}
            {this.renderErrorDialog()}
          </>
        )}
      </>
    );
  }

  renderErrorDialog() {
    return (
      <ModalError
        errorKey="common.generalError"
        onConfirm={() => {
          this.setState({
            loadStatus: STATUS_LOADED,
          });
        }}
      />
    );
  }

  renderFieldBlocks(item, fieldsTexts, testIdPrefix, callback) {
    const { blocks, isShadowBoxNeeded, labelAddOn } = item;
    const mainKey = item.id;
    const customClass = 'custom-form-field';
    const containerTestId = testIdPrefix ? `${testIdPrefix}-${mainKey}-container` : undefined;
    const infoTextId = testIdPrefix ? `${testIdPrefix}-${mainKey}-info` : undefined;

    return (
      <>
        {labelAddOn ? (
          <div style={{ paddingTop: 30 }}>
            <Label testId={infoTextId}>{labelAddOn}</Label>
          </div>
        ) : (
          <></>
        )}
        <div
          style={
            isShadowBoxNeeded ? { display: 'flex', boxShadow: '0 1px 4px 0 rgb(0 0 0 / 30%)' } : { display: 'flex' }
          }
          data-testid={containerTestId}
        >
          {blocks.map(block => {
            return (
              <CustomFormGroup keyName={mainKey} key={`SimpleFormGroup-${block.id}`}>
                {callback(
                  block.subItems.map(subItem => ({
                    ...subItem,
                    id: `${mainKey}.${subItem.id}`,
                  })),
                  fieldsTexts,
                  testIdPrefix,
                  true,
                  customClass,
                )}
              </CustomFormGroup>
            );
          })}
        </div>
      </>
    );
  }

  renderFields(fieldsRenderConfig, fieldsTexts, testIdPrefix, marginAlreadyAdded = false, customClass) {
    return (
      <div>
        {fieldsRenderConfig.map(item => {
          const {
            id,
            subItems,
            isNeedWrap,
            isNotRenderValidation,
            isValidationRender,
            isCustomNestedElement,
            customName,
            isBlock,
            hasNoLabel,
            useRequiredLabel,
          } = item;

          if (isBlock) {
            return this.renderFieldBlocks(item, fieldsTexts, testIdPrefix, this.renderFields);
          }

          if (isCustomNestedElement) {
            const { ConstructorElement, constructorProps } = item;
            return <ConstructorElement {...constructorProps} />;
          }

          const marginClass = marginAlreadyAdded ? '' : 'u-mb';
          const containerTestId = testIdPrefix ? `${testIdPrefix}-${id}-container` : undefined;

          if (isEmpty(subItems) && !isNeedWrap) {
            return (
              <div className={marginClass} key={item.id} data-testid={containerTestId}>
                {BaseFormFields.renderCommonFramework({
                  id: customName || item.id,
                  label: (
                    <>
                      {' '}
                      {/* input label */}
                      {getConfigSection(fieldsTexts, item.id)}
                      {item.labelAddOn}
                    </>
                  ),
                  renderFieldComponent: item.function,
                  isNotRenderValidation: false,
                  hasNoLabel: hasNoLabel,
                  customClass: customClass,
                  useRequiredLabel,
                  testIdPrefix,
                })}
              </div>
            );
          }

          if (isNeedWrap) {
            return (
              <SimpleFormGroup keyName={item.id} key={`SimpleFormGroup-${item.id}`} testId={containerTestId}>
                <div key={item.id}>
                  {BaseFormFields.renderCommonFramework({
                    id: item.id,
                    label: (
                      <>
                        {' '}
                        {/* input label */}
                        {getConfigSection(fieldsTexts, item.id)}
                        {item.labelAddOn}
                      </>
                    ),
                    renderFieldComponent: item.function,
                    isNotRenderValidation: isNotRenderValidation,
                    useRequiredLabel,
                    testIdPrefix,
                  })}
                </div>
              </SimpleFormGroup>
            );
          }

          const mainKey = item.id;
          const errorMessageTestId = testIdPrefix ? `${testIdPrefix}-${id}-error-message` : undefined;

          return (
            <SimpleFormGroup keyName={mainKey} key={`SimpleFormGroup-${mainKey}`} testId={containerTestId}>
              {this.renderFields(
                subItems.map(subItem => ({
                  ...subItem,
                  id: `${mainKey}.${subItem.id}`,
                })),
                fieldsTexts,
                testIdPrefix,
                true,
              )}
              {isValidationRender && <ValidationFieldErrorMessage fieldName={mainKey} testId={errorMessageTestId} />}
            </SimpleFormGroup>
          );
        })}
      </div>
    );
  }
}
