import React from 'react';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';
import ConfigurationDataApi from '../../../api/ConfigurationData';

export default class EditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.addCurrency = this.addCurrency.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { textsKey } = this.props;
    const { authToken, onBack, onCancel, isVisible, isFormEditable } = this.props;
    const overridenInitialValues = {
      isoCode: '',
      name: '',
    };

    return (
      <BaseEditForm
        data={{}}
        authToken={authToken}
        textsKey={textsKey}
        onBack={onBack}
        onSaveModifiedItem={() => {}}
        onSaveNewItem={this.addCurrency}
        onCancel={onCancel}
        isVisible={isVisible}
        fieldsComponent={{
          type: FormFields,
        }}
        validationSchema={FormFields.validationSchema}
        isFormEditable={isFormEditable}
        overridenInitialValues={overridenInitialValues}
      />
    );
  }

  async addCurrency(currency) {
    const { onBack } = this.props;
    await this.configurationDataApi.addCurrency(currency);
    onBack();
  }
}
