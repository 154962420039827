import React from 'react';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { ROUTE_KEYS } from '../../../common/constants';
import { Spinner } from 'cj-common-components';

export default class EditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadCustomerGroupList = this.loadCustomerGroupList.bind(this);
    this.modifyCustomerGroup = this.modifyCustomerGroup.bind(this);
    this.addCustomerGroup = this.addCustomerGroup.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.state = {
      isLoading: true,
      isCreationFlow: false,
      data: { name: '', description: '' },
    };
  }

  async componentDidMount() {
    const {
      match: {
        params: { customerGroupId },
      },
    } = this.props;
    this.setState({
      customerGroupId,
    });
    if (customerGroupId === ROUTE_KEYS.creation) {
      await this.loadCustomerGroupList().then(data => {
        const customerGroupsNames = data.map(item => item.name) || [];

        this.setState({
          isLoading: false,
          customerGroupsNames,
          isCreationFlow: true,
        });
      });
    } else {
      await this.loadCustomerGroupList().then(data => {
        const customerGroup = data.find(customerGroup => customerGroup.sortKey === customerGroupId);

        if (customerGroup) {
          this.setState({
            isLoading: false,
            data: customerGroup,
            isCreationFlow: false,
          });
        }
      });
    }
  }

  render() {
    const { isLoading } = this.state;
    return isLoading ? this.renderSpinneredMainLayout() : this.editForm();
  }

  renderSpinneredMainLayout = () => {
    return (
      <Spinner section small={false}>
        {this.editForm()}
      </Spinner>
    );
  };

  editForm() {
    const { authToken, onBack, onCancel, isVisible, isFormEditable, textsKey } = this.props;
    const { isCreationFlow, customerGroupsNames, data } = this.state;

    return (
      <BaseEditForm
        data={data}
        authToken={authToken}
        textsKey={textsKey}
        onBack={onBack}
        onSaveModifiedItem={this.modifyCustomerGroup}
        onSaveNewItem={this.addCustomerGroup}
        onCancel={onCancel}
        isVisible={isVisible}
        fieldsComponent={{
          type: FormFields,
        }}
        validationSchema={() => FormFields.validationSchema(customerGroupsNames, isCreationFlow)}
        isFormEditable={isFormEditable}
        isCreationFlow={isCreationFlow}
      />
    );
  }

  loadCustomerGroupList() {
    return this.configurationDataApi.getCustomerGroups();
  }

  async addCustomerGroup(customerGroup) {
    const { onBack } = this.props;
    await this.configurationDataApi.addCustomerGroup(customerGroup);
    onBack();
  }

  async modifyCustomerGroup(customerGroup) {
    const { onBack } = this.props;
    await this.configurationDataApi.modifyCustomerGroup(customerGroup);
    onBack();
  }
}
