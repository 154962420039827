import React from 'react';
import * as Yup from 'yup';
import { Label, Button, Select, Input } from 'cj-common-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import { RequiredFieldSymbol } from '../../common-components/RequiredFieldSymbol';
import SimpleFormGroup from '../../common-components/SimpleFormGroup';
import { getConfigSection } from '../../../common/utils';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { addDefaultSelectOptionToSelectValues } from '../../common-components/common-functions';
import { AUTHENTICATION_TYPES_LIST } from '../../../common/constants';
import { AUTHENTICATION_TYPES } from '../../../common/constants';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';

const uiTexts = require('../../../resources/uiTexts.json');
const buttonTexts = getConfigSection(uiTexts, 'merchants.authenticationSettings.form.buttons');

const errorsTexts = require('../../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

const isOauthIncluded = authenticationType =>
  authenticationType === AUTHENTICATION_TYPES.OAUTH || authenticationType === AUTHENTICATION_TYPES.BASIC_AND_OAUTH;

class AuthenticationTypeForm extends React.PureComponent {
  static propTypes = {
    authenticationType: PropTypes.string.isRequired,
    oauthProviderUserId: PropTypes.string,
    changeAuthenticationTypeView: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    
    const { authToken } = this.props;

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authenticationType, changeAuthenticationTypeView, oauthProviderUserId, submitForm, isFormEditable } = this.props;

    const validationSchema = Yup.object().shape({
      authenticationType: Yup.string()
        .required()
        .oneOf(AUTHENTICATION_TYPES_LIST),
      oauthProviderUserId: Yup.string().when('authenticationType', (authenticationType, schema) => {
        if (isOauthIncluded(authenticationType)) {
          return schema.required(error.mandatoryField);
        }

        return schema;
      }),
    });

    const formTexts = getConfigSection(uiTexts, 'merchants.authenticationSettings.form');
    const testIdPrefix = 'authentication-settings-form';

    const types = addDefaultSelectOptionToSelectValues(
      AUTHENTICATION_TYPES_LIST.filter(type => type !== authenticationType).map(item => ({
        value: item,
        label: getConfigSection(uiTexts, `merchants.authenticationSettings.form.types.${item.toLowerCase()}`) || '',
      })),
      authenticationType,
      getConfigSection(uiTexts, `merchants.authenticationSettings.form.types.${authenticationType.toLowerCase()}`) ||
        '',
    );

    return (
      <div className="u-mt-large" data-testid={`${testIdPrefix}-container`}>
        <RequiredFieldsTip />
        <SimpleFormGroup keyName="add-authentication-key">
          <h3 className="u-mb-small" data-testid={`${testIdPrefix}-header`}>
            {formTexts.header}
          </h3>
          <Formik
            initialValues={{ authenticationType, oauthProviderUserId }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={submitForm}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Label testId={`${testIdPrefix}-authenticationType-label`}>
                  <RequiredFieldSymbol />
                  {formTexts.fields.authenticationType}
                </Label>

                <Select
                  name="authenticationType"
                  value={values.authenticationType}
                  values={types}
                  onChange={e => {
                    changeAuthenticationTypeView(e.target.value);
                    handleChange(e);
                  }}
                  testId={`${testIdPrefix}-authenticationType-select`}
                  disabled={!isFormEditable}
                />

                <ErrorMessage name="authenticationType">
                  {message => (
                    <p
                      className="c-error-message u-mb-xsmall"
                      data-testid={`${testIdPrefix}-authenticationType-error-message`}
                    >
                      {message}
                    </p>
                  )}
                </ErrorMessage>

                {isOauthIncluded(values.authenticationType) && (
                  <>
                    <Label testId={`${testIdPrefix}-oauthProviderUserId-label`}>
                      <RequiredFieldSymbol />
                      {formTexts.fields.oauthProviderUserId}
                    </Label>

                    <Input
                      type="text"
                      name="oauthProviderUserId"
                      value={values.oauthProviderUserId}
                      testId={`${testIdPrefix}-oauthProviderUserId-input`}
                      onChange={handleChange}
                      disabled={!isFormEditable}
                    />

                    <ErrorMessage name="oauthProviderUserId">
                      {message => (
                        <p
                          className="c-error-message u-mb-xsmall"
                          data-testid={`${testIdPrefix}-oauthProviderUserId-error-message`}
                        >
                          {message}
                        </p>
                      )}
                    </ErrorMessage>
                  </>
                )}

                <Button className="u-mt-small" secondary type="submit" testId="save-button" disabled={!isFormEditable}>
                  {buttonTexts.save}
                </Button>
              </Form>
            )}
          </Formik>
        </SimpleFormGroup>
      </div>
    );
  }
}

export default connect()(AuthenticationTypeForm);
