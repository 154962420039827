import React from 'react';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { ROUTE_KEYS } from '../../../common/constants';
import { Spinner } from 'cj-common-components';

export default class EditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadProductGroupList = this.loadProductGroupList.bind(this);
    this.modifyProductGroup = this.modifyProductGroup.bind(this);
    this.addProductGroup = this.addProductGroup.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.state = {
      isLoading: true,
      isCreationFlow: false,
      data: { name: '', description: '' },
    };
  }

  async componentDidMount() {
    const {
      match: {
        params: { productGroupId },
      },
    } = this.props;
    this.setState({
      productGroupId,
    });
    if (productGroupId === ROUTE_KEYS.creation) {
      await this.loadProductGroupList().then(data => {
        const productGroupsNames = data.map(item => item.name) || [];

        this.setState({
          isLoading: false,
          productGroupsNames,
          isCreationFlow: true,
        });
      });
    } else {
      await this.loadProductGroupList().then(data => {
        const productGroup = data.find(productGroup => productGroup.sortKey === productGroupId);

        if (productGroup) {
          this.setState({
            isLoading: false,
            data: productGroup,
            isCreationFlow: false,
          });
        }
      });
    }
  }

  render() {
    const { isLoading } = this.state;
    return isLoading ? this.renderSpinneredMainLayout() : this.editForm();
  }

  renderSpinneredMainLayout = () => {
    return (
      <Spinner section small={false}>
        {this.editForm()}
      </Spinner>
    );
  };

  editForm() {
    const { authToken, onBack, onCancel, isVisible, isFormEditable, textsKey } = this.props;
    const { isCreationFlow, productGroupsNames, data } = this.state;

    return (
      <BaseEditForm
        data={data}
        authToken={authToken}
        textsKey={textsKey}
        onBack={onBack}
        onSaveModifiedItem={this.modifyProductGroup}
        onSaveNewItem={this.addProductGroup}
        onCancel={onCancel}
        isVisible={isVisible}
        fieldsComponent={{
          type: FormFields,
        }}
        validationSchema={() => FormFields.validationSchema(productGroupsNames, isCreationFlow)}
        isFormEditable={isFormEditable}
        isCreationFlow={isCreationFlow}
      />
    );
  }

  loadProductGroupList() {
    return this.configurationDataApi.getProductGroups();
  }

  async addProductGroup(productGroup) {
    const { onBack } = this.props;
    await this.configurationDataApi.addProductGroup(productGroup);
    onBack();
  }

  async modifyProductGroup(productGroup) {
    const { onBack } = this.props;
    await this.configurationDataApi.modifyProductGroup(productGroup);
    onBack();
  }
}
