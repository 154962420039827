import React from 'react';
import { Spinner } from 'cj-common-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import BaseEditForm from '../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../common/common-prop-types';
import { ROUTE_KEYS, ROUTES } from '../../common/constants';
import ConfigurationDataApi from '../../api/ConfigurationData';
import { RequiredFieldsTip } from '../common-components/RequiredFieldsTip';

export class MerchantGroupEditForm extends React.PureComponent {
  static propTypes = {
    routeProps: PropTypes.shape({
      location: commonPropTypes.router.location,
      history: commonPropTypes.router.history,
      match: commonPropTypes.router.match,
    }),
    authToken: commonPropTypes.authToken,
    merchantGroupData: commonPropTypes.editForm.data,
    isFormEditable: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const { authToken, merchantGroupData } = this.props;
    this.handleGoBack = this.handleGoBack.bind(this);
    this.addMerchantGroup = this.addMerchantGroup.bind(this);
    this.modifyMerchantGroup = this.modifyMerchantGroup.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.state = {
      isLoading: false,
      merchantGroupData,
    };
  }

  render() {
    const { authToken, isFormEditable } = this.props;
    const { merchantGroupData, isLoading } = this.state;

    const overridenInitialValues = {
      name: '',
    };

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        <RequiredFieldsTip />
        <BaseEditForm
          data={{ ...merchantGroupData }}
          authToken={authToken}
          textsKey={ROUTE_KEYS.merchantGroups}
          onBack={this.handleGoBack}
          onSaveModifiedItem={this.modifyMerchantGroup}
          onSaveNewItem={this.addMerchantGroup}
          onCancel={this.handleGoBack}
          isVisible
          fieldsComponent={{
            type: FormFields,
          }}
          overridenInitialValues={overridenInitialValues}
          validationSchema={FormFields.validationSchema}
          isFormEditable={isFormEditable}
          customProps={{ merchantGroupData }}
          isSaveButtonEnable
        />
      </>
    );
  }

  modifyMerchantGroup(merchantGroup) {
    this.setState({ isLoading: true });
    this.configurationDataApi.updateMerchantGroup(merchantGroup).then(() => {
      this.setState({ isLoading: false, merchantGroupData: merchantGroup });
    });
  }

  async addMerchantGroup(merchantGroup) {
    this.configurationDataApi.addMerchantGroup(merchantGroup).then(() => {
      this.setState({ isLoading: false });
      this.handleGoBack();
    });
  }

  handleGoBack() {
    this.props.history.replace(ROUTES.merchantGroups);
  }
}

// export default MerchantGroupEditForm;
// export default connect()(MerchantGroupEditForm);
export default connect()(withRouter(MerchantGroupEditForm));
