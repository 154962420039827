import React from 'react';
import BaseEditForm from '../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../common/common-prop-types';
import ConfigurationDataApi from '../../api/ConfigurationData';

export default class EditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { data, textsKey, authToken, onBack, isFormEditable, onSaveModifiedItem } = this.props;

    const overridenInitialValues = {
      role: '',
    };

    return (
      <BaseEditForm
        data={data}
        authToken={authToken}
        textsKey={textsKey}
        onBack={onBack}
        onSaveModifiedItem={onSaveModifiedItem}
        onSaveNewItem={() => {}}
        overridenInitialValues={overridenInitialValues}
        validationSchema={FormFields.validationSchema}
        isFormEditable={isFormEditable}
        fieldsComponent={{
          type: FormFields,
        }}
      />
    );
  }
}
