import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'cj-common-components';
import PropTypes from 'prop-types';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';
import { ROUTE_KEYS, ROUTES } from '../../../common/constants';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { setMerchantGroupEditUserName, setIsLoading } from '../../../redux/breadCrumbsSlice';

class UserEditForm extends React.PureComponent {
  static propTypes = {
    routeProps: PropTypes.shape({
      location: commonPropTypes.router.location,
      history: commonPropTypes.router.history,
      match: commonPropTypes.router.match,
    }),
    authToken: commonPropTypes.authToken,
    merchantGroupData: commonPropTypes.editForm.data,
  };

  constructor(props) {
    super(props);
    const { authToken, merchantGroupData } = this.props;
    this.handleGoBack = this.handleGoBack.bind(this);
    this.getAllUnassignedUsers = this.getAllUnassignedUsers.bind(this);
    this.addUserToMerchantGroup = this.addUserToMerchantGroup.bind(this);
    this.updateMerchanGroupUser = this.updateMerchanGroupUser.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.setPreparedData = this.setPreparedData.bind(this);
    this.state = {
      isLoading: true,
      selectedUser: null,
      merchantGroupData,
    };
  }

  componentDidMount() {
    const {
      merchantGroupData,
      dispatch,
      routeProps: {
        match: {
          params: { userId },
        },
      },
    } = this.props;

    if (userId) {
      dispatch(setIsLoading({ isLoading: true }));
      this.getMerchantGroupUser(merchantGroupData.id, userId);
    } else {
      (async () => {
        const getAllUnassignedUsers = await this.getAllUnassignedUsers();

        this.setState({
          userList: getAllUnassignedUsers,
          isLoading: false,
        });
      })();
    }
  }

  render() {
    const { merchantGroupData, authToken, isFormEditable } = this.props;
    const { isLoading, selectedUser, userList } = this.state;

    const overridenInitialValues = {
      userId: '',
      accessLevel: '',
    };

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <BaseEditForm
        data={selectedUser || {}}
        authToken={authToken}
        textsKey={ROUTE_KEYS.merchantGroups}
        onBack={this.handleGoBack}
        onSaveModifiedItem={this.updateMerchanGroupUser}
        onSaveNewItem={this.addUserToMerchantGroup}
        onCancel={this.handleGoBack}
        isVisible
        fieldsComponent={{
          type: FormFields,
        }}
        overridenInitialValues={overridenInitialValues}
        validationSchema={FormFields.validationSchema}
        isFormEditable={isFormEditable}
        customProps={{ merchantGroupData, selectedUser, userList }}
        isSaveButtonEnable
      />
    );
  }

  async getAllUnassignedUsers() {
    const { merchantGroupData } = this.state;

    const addedUsers = await this.configurationDataApi.getMerchantGroupUsers(merchantGroupData.id);
    const allUsers = await this.configurationDataApi.getUsers();

    return allUsers?.filter(el => {
      return !addedUsers.users.find(element => {
        return element.userId === el.username;
      });
    });
  }

  getMerchantGroupUser(merchantGroupId, userId) {
    this.configurationDataApi.getMerchantGroupUser(merchantGroupId, userId).then(result => {
      this.setPreparedData(result);
    });
  }

  setPreparedData(user) {
    const { dispatch } = this.props;

    dispatch(setMerchantGroupEditUserName({ merchantGroupEditUserName: user.userId }));
    this.setState({
      selectedUser: user,
      isLoading: false,
    });
    dispatch(setIsLoading({ isLoading: false }));
  }

  async addUserToMerchantGroup(user) {
    const { merchantGroupData } = this.props;
    await this.configurationDataApi.addMerchantGroupUser(merchantGroupData.id, user).then(() => {
      this.setState({ isLoading: false });
      this.handleGoBack();
    });
  }

  async updateMerchanGroupUser(user) {
    const { merchantGroupData } = this.props;

    await this.configurationDataApi.updateMerchantGroupUser(merchantGroupData.id, user).then(() => {
      this.setState({ isLoading: false });
      this.handleGoBack();
    });
  }

  handleGoBack() {
    const { merchantGroupData } = this.props;
    this.props.history.replace(`${ROUTES.merchantGroups}/${merchantGroupData.id}`);
  }
}

export default connect()(withRouter(UserEditForm));
