import React from 'react';
import PropTypes from 'prop-types';
import TooltipIconButton from './TooltipIconButton';
import { omit } from '@turbopay/ts-helpers/object-utils';
import { removeEmptyValuesFromObject } from '../../common/utils';
import { withRouter } from 'react-router-dom';

class DownloadRowDataButton extends React.PureComponent {
  static propTypes = {
    rowId: PropTypes.string.isRequired,
    rawData: PropTypes.object.isRequired,
    tooltipText: PropTypes.string.isRequired,
    iconText: PropTypes.string.isRequired,
  };

  render() {
    const { rawData, rowId, tooltipText, iconText } = this.props;
    const data = removeEmptyValuesFromObject(rawData);
    const modifiedData = omit(['internalId'], data);
    return (
      <a
        href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(modifiedData))}`}
        download={`${rowId}.json`}
      >
        <TooltipIconButton
          tooltipText={tooltipText}
          buttonProps={{
            className: 'c-table__btn--border',
            secondary: true,
            small: true,
            icon: iconText,
          }}
          testId="download-button"
        />
      </a>
    );
  }
}

export default withRouter(DownloadRowDataButton);
