import React from 'react';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';
import { getConfigSection } from '../../../common/utils';

const uiTexts = require('../../../resources/uiTexts.json');

export default class EditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  render() {
    const {
      data,
      authToken,
      textsKey,
      parentHeader,
      onSaveModifiedItem,
      onSaveNewItem,
      onBack,
      onCancel,
      isVisible,
      isFormEditable,
      customProps,
    } = this.props;

    const headerText = getConfigSection(uiTexts, `${textsKey}.form.header`);

    return (
      <BaseEditForm
        data={data}
        authToken={authToken}
        textsKey={textsKey}
        parentLevelHeader={parentHeader}
        currentLevelHeader={headerText}
        onBack={onBack}
        onSaveModifiedItem={onSaveModifiedItem}
        onSaveNewItem={onSaveNewItem}
        onCancel={onCancel}
        isVisible={isVisible}
        fieldsComponent={{
          type: FormFields,
        }}
        validationSchema={FormFields.validationSchema}
        isFormEditable={isFormEditable}
        customProps={customProps}
      />
    );
  }
}
