import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonContainer, Column, Layout } from 'cj-common-components';
import { Formik, Form } from 'formik';
import { isEmpty, isObject, omit, get } from '@turbopay/ts-helpers/object-utils';
import { getConfigSection } from '../../common/utils';
import commonPropTypes from '../../common/common-prop-types';

const uiTexts = require('../../resources/uiTexts.json');
const config = require('../../resources/config.json');

export default class BaseEditForm extends React.PureComponent {
  static propTypes = {
    // data structure varies depending on the component, therefore
    // Imposible to define a common structure for this parameter
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
    authToken: commonPropTypes.authToken,
    textsKey: PropTypes.string.isRequired,
    onSaveModifiedItem: PropTypes.func.isRequired,
    onSaveNewItem: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    isFormEditable: PropTypes.bool.isRequired,
    // Imposible to define a common structure for this parameter
    // eslint-disable-next-line react/forbid-prop-types
    fieldsComponent: PropTypes.object.isRequired,
    // Imposible to define a common structure for this parameter
    // eslint-disable-next-line react/forbid-prop-types
    overridenInitialValues: PropTypes.object,
    // Imposible to define a common structure for this parameter
    // eslint-disable-next-line react/forbid-prop-types
    validationSchema: PropTypes.func,
    // This prop is a free-content object , therefore
    // imposible to define a unique structure
    // eslint-disable-next-line react/forbid-prop-types
    customProps: PropTypes.object,
    isSaveButtonVisible: PropTypes.bool,
    isSaveButtonEnable: PropTypes.bool,
    formAppendComponent: PropTypes.func,
    additionalButtonComponents: PropTypes.array,
  };

  static defaultProps = {
    overridenInitialValues: {},
    isSaveButtonVisible: true,
    isSaveButtonEnable: true,
    additionalButtonComponents: [],
  };

  constructor(props) {
    super(props);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  render() {
    const {
      data,
      authToken,
      textsKey,
      fieldsComponent,
      overridenInitialValues,
      additionalInitialValues,
      validationSchema,
      isFormEditable,
      customProps,
      dataStatusLoadCallback,
      formAppendComponent,
    } = this.props;

    const texts = this.props.uiTexts || uiTexts;

    // Weird workaround for Formik: it only renders the form once, so
    // we need to wait until this.props.data contains info. Otherwise the
    // initial values are always empty
    const internalId = get(data, 'internalId');

    const payload = Object.assign({}, data);
    delete payload.internalId;
    const fieldsToRender = getConfigSection(texts, `${textsKey}.form.fields`);
    const SpecificFormFieldsComponent = fieldsComponent.type;
    const initialValues = isEmpty(payload)
      ? {
          ...this.initializeFieldsForNewItem(fieldsToRender),
          ...overridenInitialValues,
          // we need internalId in the init values in order to not losing it
          // when sent back to the table. It is harmless (it is not rendered
          // neither modified)
          internalId,
        }
      : Object.assign({}, additionalInitialValues, data);

    return (
      <div className="u-mb-large">
        <Formik
          // TODO: Yup validations don´t work properly if we use
          // null or undefined as initial values. We need to use
          // '' instead. Could it be because of the combination of
          // Formik/Yup/controlled vs uncontrolled component ?
          validationSchema={() => validationSchema(this.isUpdateOperation())}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={this.handleSubmitForm}
        >
          {props => (
            <Form>
              <SpecificFormFieldsComponent
                authToken={authToken}
                formikProps={props}
                textsKey={textsKey}
                isUpdateOperation={this.isUpdateOperation()}
                isFormEditable={isFormEditable}
                customProps={customProps}
                dataStatusLoadCallback={dataStatusLoadCallback}
              />
              {formAppendComponent && formAppendComponent()}
              <Layout center className="u-mt-xxsmall">
                <Column span="6/12">{this.renderButtons()}</Column>
              </Layout>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  renderButtons() {
    const {
      isSaveButtonVisible,
      onBack,
      isSaveButtonEnable,
      isFormEditable = true,
      additionalButtonComponents,
    } = this.props;
    const texts = this.props.uiTexts || uiTexts;
    const enabledSaveButton = isSaveButtonEnable && isFormEditable;
    const buttonTexts = getConfigSection(texts, 'common.editForm.buttons');
    const buttonIcons = getConfigSection(config, 'ui.common.editForm.icons');
    const children = [];
    children.push(
      <Button
        key="base-edit-form-button-back"
        secondary
        type="button"
        icon={buttonIcons.back}
        iconReversed
        onClick={onBack}
        testId="back-button"
      >
        {buttonTexts.back}
      </Button>,
    );
    if (isSaveButtonVisible) {
      children.push(
        <Button
          key="base-edit-form-button-submit"
          secondary
          type="submit"
          icon={buttonIcons.save}
          iconReversed
          disabled={!enabledSaveButton}
          testId="save-button"
        >
          {buttonTexts.save}
        </Button>,
      );
    }
    if (additionalButtonComponents) {
      additionalButtonComponents.forEach(additionalButtonComponent => {
        children.push(
          <Button
            key={additionalButtonComponent.key}
            secondary
            type="button"
            icon={additionalButtonComponent.icon}
            iconReversed
            onClick={additionalButtonComponent.onClick}
            testId={additionalButtonComponent.testId}
          >
            {additionalButtonComponent.text}
          </Button>,
        );
      });
    }
    return <ButtonContainer>{children}</ButtonContainer>;
  }

  isUpdateOperation() {
    const { data, isCreationFlow } = this.props;

    if (isCreationFlow !== undefined) {
      return !isCreationFlow;
    }

    return !isEmpty(omit(['internalId'], data));
  }

  handleSubmitForm(values) {
    const { preSubmitHook } = this.props;
    let transformedValues = {};
    if (preSubmitHook) {
      transformedValues = preSubmitHook(values);
    } else {
      transformedValues = values;
    }
    const { onSaveModifiedItem, onSaveNewItem } = this.props;
    if (this.isUpdateOperation()) {
      onSaveModifiedItem(transformedValues);
    } else {
      onSaveNewItem(transformedValues);
    }
  }

  initializeFieldsForNewItem(fieldsToRender) {
    return Object.keys(fieldsToRender).reduce((accum, currField) => {
      if (!isObject(fieldsToRender[currField])) {
        const newAccum = { ...accum };
        newAccum[currField] = undefined;
        return newAccum;
      }

      const newAccum = { ...accum };
      newAccum[currField] = this.initializeFieldsForNewItem(fieldsToRender[currField]);
      return newAccum;
    }, {});
  }
}
