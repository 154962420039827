import { COUNTRY_CODES_POOL_MODEL } from './external-definitions/country-codes';

export { CURRENCY_CODES } from './external-definitions/currency-codes';
export { LOCALE_CODES } from './external-definitions/locale-codes';

function adaptCountryCodesPoolModel() {
  return Object.keys(COUNTRY_CODES_POOL_MODEL).map(key => ({
    isoCode: COUNTRY_CODES_POOL_MODEL[key].value,
    name: key,
  }));
}
export const COUNTRY_CODES = adaptCountryCodesPoolModel();

export const USER_ROLE = {
  ROLE_NONE: 'None',
  ROLE_ADMIN: 'Administrator',
  ROLE_OPERATOR: 'Operator',
  ROLE_AUDITOR: 'Auditor',

  getName() {
    return 'USER_ROLE';
  },
};

export const OPERATOR_ACCESS_LEVEL = {
  READ: 'READ',
  WRITE: 'WRITE',
  getName() {
    return 'OPERATOR_ACCESS_LEVEL';
  },
};

export const ACCESS_TYPE = {
  DIRECT: 'DIRECT',
  GROUP: 'GROUP',
  getName() {
    return 'ACCESS_TYPE';
  },
};

export const PAYMENT_OPTIONS = {
  VISA: 'VISA',
  MSTRCRD: 'MSTRCRD',
  BNKACCT: 'BNKACCT',
  PAYPAL: 'PAYPAL',
  PAYU: 'PAYU',
  PAYUBLK: 'PAYUBLK',
  PAYUBNK: 'PAYUBNK',
  IDEAL: 'IDEAL',
  JCB: 'JCB',
  AMEX: 'AMEX',
  CRTBANCAIR: 'CRTBANCAIR',
  PREPMNT: 'PREPMNT',
  PAYINVC: 'PAYINVC',
  getName() {
    return 'PAYMENT_OPTIONS';
  },
};

export const PAYMENT_OPTIONS_NAMES = {
  VISA: 'Visa',
  MSTRCRD: 'Mastercard',
  BNKACCT: 'SEPA Direct Debit',
  PAYPAL: 'PayPal',
  PAYU: 'PayU',
  PAYUBLK: 'PayU BLIK',
  PAYUBNK: 'PayU Bank Transfer',
  IDEAL: 'Ideal',
  JCB: 'JCB',
  AMEX: 'Amex',
  CRTBANCAIR: 'Carte Bancaire',
  PREPMNT: 'Prepayment',
  PAYINVC: 'Pay upon Invoice',
  APPLPAY: 'Apple Pay',
  getName() {
    return 'PAYMENT_OPTIONS_NAMES';
  },
};

export const PAYMENT_OPTIONS_STORED = {
  VISA: 'VISA',
  MSTRCRD: 'MSTRCRD',
  BNKACCT: 'BNKACCT',
  PAYPAL: 'PAYPAL',
  JCB: 'JCB',
  AMEX: 'AMEX',
  CRTBANCAIR: 'CRTBANCAIR',
  getName() {
    return 'PAYMENT_OPTIONS_STORED';
  },
};

export const USER_STATUS = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
  getName() {
    return 'USER_STATUS';
  },
};

export const CHANNEL_RULE_OPERATORS = ['<', '<=', '=', '>=', '>'];

export const BUSINESS_DOMAINS = ['JPMMPS', 'VWPS'];
export const WIDGET_HEADER_IMAGE_POSITION = [
  { label: 'Right', value: 'RIGHT' },
  { label: 'Left', value: 'LEFT' },
  { label: 'Center', value: 'CENTER' },
];

export const PAYMENT_OPTION_PROCESSING_TYPE = [
  {
    value: 'sync',
    name: 'Sync',
  },
  {
    value: 'async',
    name: 'Async',
  },
];

export const PAYMENT_OPTIONS_SDK_CONFIG = {
  VISA: {
    name: 'VISA',
    group: 'CC',
  },
  MSTRCRD: {
    name: 'MASTERCARD',
    group: 'CC',
  },
  PAYPAL: {
    name: 'PAYPAL',
  },
  BNKACCT: {
    name: 'SEPA',
  },
  PAYU: {
    name: 'PAYU',
  },
  PAYUBLK: {
    name: 'PAYUBLK',
  },
  PAYUBNK: {
    name: 'PAYUBNK',
  },
  IDEAL: {
    name: 'IDEAL',
  },
  JCB: {
    name: 'JCB',
    group: 'CC',
  },
  AMEX: {
    name: 'AMEX',
    group: 'CC',
  },
  CRTBANCAIR: {
    name: 'CRTBANCAIR',
    group: 'CC',
  },
  PREPMNT: {
    name: 'PREPMNT',
  },
  PAYINVC: {
    name: 'PAYINVC',
  },
  APPLPAY: {
    name: 'APPLPAY',
  },
};

export const DATA_STATUS = {
  DATA_LOADED: 'DATA_LOADED',
  DATA_LOADING: 'DATA_LOADING',
  DATA_ERROR: 'DATA_ERROR',
};

export const TYPES = {
  JSON: 'JSON',
  XML: 'XML',
  X_WWW_FORM_URLENCODED: 'X-www-form-urlencoded',
  PUT: 'PUT',
  POST: 'POST',
  AT_SPECIFIC_TIME: 'SPECIFIC_TIME',
  AT_SPECIFIC_INTERVALS: 'SPECIFIC_INTERVALS',
};

export const AUTHENTICATION_TYPES = {
  BASIC: 'BASIC',
  OAUTH: 'OAUTH',
  BASIC_AND_OAUTH: 'BASIC_AND_OAUTH',
  NO_AUTH: 'NO_AUTHENTICATION',
};

export const NOTIFICATIONS_AUTHENTICATION_TYPES = [
  AUTHENTICATION_TYPES.NO_AUTH,
  AUTHENTICATION_TYPES.BASIC,
  AUTHENTICATION_TYPES.OAUTH,
];

export const AUTHENTICATION_TYPES_LIST = [
  AUTHENTICATION_TYPES.BASIC,
  AUTHENTICATION_TYPES.OAUTH,
  AUTHENTICATION_TYPES.BASIC_AND_OAUTH,
];

export const CONTENT_TYPES = [TYPES.JSON, TYPES.XML, TYPES.X_WWW_FORM_URLENCODED];

export const REQUEST_TYPES = [TYPES.PUT, TYPES.POST];

export const RETRY_SCHEME_TYPES = [TYPES.AT_SPECIFIC_TIME, TYPES.AT_SPECIFIC_INTERVALS];

export const PCI_TYPE = {
  PAYON: 'payon',
  PAYON_WITH_PCI_PROXY: 'payonWithPCIProxy',
  UPCF: 'upcf',
};

export const PCI_TYPES = [PCI_TYPE.PAYON, PCI_TYPE.PAYON_WITH_PCI_PROXY];

export const EXEMPTION_FLAG = {
  NO: 'NO',
  LOW_VALUE: 'LVA',
  TRANSACTION_RISK_ANALYSIS: 'TRA',
  TRUSTED_MERCHANT_LISTING: 'TML',
  SECURE_CORPORATE_PAYMENT: 'SCP',
};

export const EXEMPTION_FLAGS = [
  EXEMPTION_FLAG.NO,
  EXEMPTION_FLAG.LOW_VALUE,
  EXEMPTION_FLAG.TRANSACTION_RISK_ANALYSIS,
  EXEMPTION_FLAG.TRUSTED_MERCHANT_LISTING,
  EXEMPTION_FLAG.SECURE_CORPORATE_PAYMENT,
];

export const PROGRAM_ACCOUNT_NUMBERS = {
  '01': '01',
  '00': '00',
};

export const SORT_COLUMNS = {
  CREATION_DATE: 'creationDate',
};

export const DISPLAY_RULE_EXPRESSION_OPERATORS = {
  EQUAL: 'equal',
  NOT_EQUAL: 'not equal',
  LIKE: 'like',
};

export const DISPLAY_RULE_EXPRESSION_ACTIONS = {
  GREY_OUT: 'grey-out',
  HIDE: 'hide',
};

export const ROUTES = {
  merchants: '/merchants',
  createMerchant: '/merchants/creation',
  merchantPage: '/merchants/:merchantId',
  merchantChannels: '/merchants/:merchantId/channels',
  channelCreation: '/merchants/:merchantId/channels/creation',
  channelPage: '/merchants/:merchantId/channels/:channelId',
  paymentsSplitConfiguration: '/merchants/:merchantId/channels/:channelId/split-payments-configuration',
  targetMerchantAccounts: '/merchants/:merchantId/channels/:channelId/target-merchant-accounts',
  targetMerchantAccount: '/merchants/:merchantId/channels/:channelId/target-merchant-accounts/:targetAccountId',
  targetMerchantAccountCreation: '/merchants/:merchantId/channels/:channelId/target-merchant-accounts/creation',
  merchantUsersAndGroups: '/merchants/:merchantId/users-and-groups',
  merchantEditUserForm: '/merchants/:merchantId/users-and-groups/:userId',
  merchantCreateGroupForm: '/merchants/:merchantId/users-and-groups/addGroup',
  merchantIntegration: '/merchants/:merchantId/integration',
  merchantCulture: '/merchants/:merchantId/culture',
  authenticationSettings: '/merchants/:merchantId/authentication-settings',
  merchantHistory: '/merchants/:merchantId/history',
  merchantHistoryDetails: '/merchants/:merchantId/history/:historyEntryId',
  merchantGroups: '/merchantGroups',
  createMerchantGroup: '/merchantGroups/creation',
  merchantGroupPage: '/merchantGroups/:merchantGroupId',
  merchantGroupEditUserForm: '/merchantGroups/:merchantGroupId/editUser=:userId',
  merchantGroupEditMerchantForm: '/merchantGroups/:merchantGroupId/editMerchant=:merchantId',
  merchantGroupAddUserForm: '/merchantGroups/:merchantGroupId/addUser',
  merchantGroupAddMerchantForm: '/merchantGroups/:merchantGroupId/addMerchant',
  merchantCorsDomains: '/merchants/:merchantId/corsDomains',
  hostedPaymentPage: '/merchants/:merchantId/hostedPaymentPage',
  networkTokenAndThreeDsManagement: '/merchants/:merchantId/networkTokenAndThreeDsManagement',
  achSettings: '/merchants/:merchantId/ach-settings',
  widgetConfiguration: '/merchants/:merchantId/widget-configuration',
  editUser: '/users/:userId',
  editUserMerchantsAccess: '/users/:userId/editMerchantAccess/:merchantId',
  createUserMerchantsAccess: '/users/:userId/createMerchantAccess',
  editUserGroupsAccess: '/users/:userId/editGroupAccess/:groupId',
  createUserGroupsAccess: '/users/:userId/createGroupAccess',
  referenceData: '/referenceData',
  referenceDataOption: '/referenceData/:selectedOptionId',
  createReferenceDataCountry: '/referenceData/countries/creation',
  createReferenceDataCurrency: '/referenceData/currencies/creation',
  createOrEditReferenceDataCustomerGroup: '/referenceData/customerGroups/:customerGroupId',
  createOrEditReferenceDataProductGroup: '/referenceData/productGroups/:productGroupId',
  createOrEditReferenceDataUpcfPaymentOption: '/referenceData/upcfPaymentOptions/:upcfPaymentOptionId',
  referenceDataUpcfPaymentOptionsArchive: '/referenceData/upcfPaymentOptions/archive',
  createOrEditReferenceDataUpcfArchivePaymentOption: '/referenceData/upcfPaymentOptions/archive/:upcfPaymentOptionId',
  merchantNotifications: '/merchants/:merchantId/notification-settings/notifications',
  notificationSettings: '/merchants/:merchantId/notification-settings',
  notificationRetries: '/merchants/:merchantId/notification-settings/notification-retries',
  createOrEditNotificationRetries: '/merchants/:merchantId/notification-settings/notification-retries/AddRetry',
  createOrEditReferenceDataUpcfPaymentOptionCustomTags:
    '/referenceData/upcfPaymentOptions/:upcfPaymentOptionId/customTags',
  createOrEditReferenceDataUpcfArchivePaymentOptionCustomTags:
    '/referenceData/upcfPaymentOptions/archive/:upcfPaymentOptionId/customTags',
  displayRuleExpressions: '/merchants/:merchantId/channels/:channelId/display-rule-expressions',
  accessDenied: '/accessDenied',
};

export const ROUTE_KEYS = {
  creation: 'creation',
  merchants: 'merchants',
  channels: 'channels',
  usersAndGroups: 'users-and-groups',
  integration: 'integration',
  history: 'history',
  culture: 'culture',
  splitPaymentsConfiguration: 'split-payments-configuration',
  authenticationSettings: 'authentication-settings',
  corsDomains: 'corsDomains',
  merchantGroups: 'merchantGroups',
  merchantHistory: 'history',
  hostedPaymentPage: 'hostedPaymentPage',
  networkTokenAndThreeDsManagement: 'networkTokenAndThreeDsManagement',
  notificationSettings: 'notification-settings',
  notifications: 'notifications',
  notificationRetries: 'notification-retries',
  achSettings: 'ach-settings',
  widgetConfiguration: 'widget-configuration',
  targetMerchantAccounts: 'target-merchant-accounts',
  customTags: 'customTags',
  displayRuleExpressions: 'display-rule-expressions',
};

export const RESOURCES_KEYS = {
  merchants: 'merchants',
  merchantChannels: 'merchants.channels',
  merchantUsers: 'merchants.users',
  merchantHistoryKeys: 'merchants.history',
  merchantHistorDetailsyKeys: 'merchants.history.details',
  merchantCorsDomains: 'merchants.corsDomains',
  merchantGroups: 'merchantGroups',
  merchantGroupsDetails: 'merchantGroups.form',
  merchantGroupsWithMerchant: 'merchants.groupsWithMerchant',
  hostedPaymentPage: 'merchants.hostedPaymentPage',
  networkTokenAndThreeDsManagement: 'merchants.networkTokenAndThreeDsManagement',
  achSettings: 'merchants.achSettings',
  widgetConfiguration: 'merchants.widgetConfiguration',
  referenceDataProductGroups: 'referenceData.productGroups',
  referenceDataCustomerGroups: 'referenceData.customerGroups',
  referenceDataCountries: 'referenceData.countries',
  referenceDataCurrencies: 'referenceData.currencies',
  referenceDataCultureSettings: 'referenceData.cultureSettings',
  referenceDataUpcfPaymentOptions: 'referenceData.upcfPaymentOptions.active',
  referenceDataUpcfPaymentOptionsArchive: 'referenceData.upcfPaymentOptions.deleted',
  referenceDataUpcfPaymentOption: 'referenceData.upcfPaymentOptions',
  merchantNotifications: 'merchants.notifications',
  notificationSettings: 'merchants.notificationSettings',
  notificationRetries: 'merchants.notificationRetries',
  targetMerchantAccounts: 'merchants.channels.form.fields.targetMerchantAccounts',
  displayRuleExpressions: 'merchants.channels.form.fields.displayRuleExpressions',
};

export const REGEX = {
  TIME_FORMAT: '^[0-9]{2,3}:[0-5][0-9]:[0-5][0-9]$',
};

export const FILE_TYPES = {
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
};

export const IMAGE_ASPECT_RATIOS = {
  '4:1': '4:1',
};

export const INTEGRATION_TYPE = {
  LEGACY: 'legacy',
  UPCF: 'upcf',
};

export const INTEGRATION_TYPES = [INTEGRATION_TYPE.LEGACY, INTEGRATION_TYPE.UPCF];

export const IMAGE_DIMENSION_COMPARATORS = {
  LESS_THAN: 'lte',
  GREATER_THAN: 'gte',
  EQUAL: 'eq',
};

export const PATCH_REQUEST_OPERATIONS = {
  ADD: 'add',
  REMOVE: 'remove',
};
