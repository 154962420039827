import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'cj-common-components';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';
import { ROUTE_KEYS, ROUTES } from '../../../common/constants';
import ConfigurationDataApi from '../../../api/ConfigurationData';

class MerchantEditForm extends React.PureComponent {
  static propTypes = {
    routeProps: PropTypes.shape({
      location: commonPropTypes.router.location,
      history: commonPropTypes.router.history,
      match: commonPropTypes.router.match,
    }),
    authToken: commonPropTypes.authToken,
    merchantGroupData: commonPropTypes.editForm.data,
  };

  constructor(props) {
    super(props);
    const { authToken, merchantGroupData } = this.props;
    this.handleGoBack = this.handleGoBack.bind(this);
    this.getAllUnassignedMerchants = this.getAllUnassignedMerchants.bind(this);
    this.addMerchantToMerchantGroup = this.addMerchantToMerchantGroup.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.state = {
      isLoading: true,
      merchantList: [],
      merchantGroupData,
    };
  }

  async componentDidMount() {
    const getAllUnassignedMerchants = await this.getAllUnassignedMerchants();

    this.setState({
      merchantList: getAllUnassignedMerchants,
      isLoading: false,
    });
  }

  render() {
    const { merchantGroupData, authToken, isFormEditable } = this.props;
    const { isLoading, merchantList } = this.state;

    const overridenInitialValues = {
      merchantName: '',
    };

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <BaseEditForm
        data={{}}
        authToken={authToken}
        textsKey={ROUTE_KEYS.merchantGroups}
        onBack={this.handleGoBack}
        onSaveModifiedItem={() => {}}
        onSaveNewItem={this.addMerchantToMerchantGroup}
        onCancel={this.handleGoBack}
        isVisible
        fieldsComponent={{
          type: FormFields,
        }}
        overridenInitialValues={overridenInitialValues}
        validationSchema={FormFields.validationSchema}
        isFormEditable={isFormEditable}
        customProps={{ merchantGroupData, merchantList }}
        isSaveButtonEnable
      />
    );
  }

  async getAllUnassignedMerchants() {
    const { merchantGroupData } = this.state;

    const addedMerchants = await this.configurationDataApi.getMerchantGroupMerchants(merchantGroupData?.id);
    const allMerchants = await this.configurationDataApi.getMerchants();

    return allMerchants?.filter(el => {
      return !addedMerchants.merchants.find(element => {
        return element.merchantId === el.id;
      });
    });
  }

  async addMerchantToMerchantGroup(merchant) {
    const { merchantGroupData } = this.props;
    this.configurationDataApi.addMerchantGroupMerchant(merchantGroupData.id, merchant.merchantName).then(() => {
      this.setState({ isLoading: false });
      this.handleGoBack();
    });
  }

  handleGoBack() {
    const { merchantGroupData } = this.props;
    this.props.history.replace(`${ROUTES.merchantGroups}/${merchantGroupData.id}`);
  }
}

export default connect()(withRouter(MerchantEditForm));
