import React from 'react';
import { Column, Heading, Layout, Table } from 'cj-common-components';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import uuid from 'uuidv4';
import { getConfigSection } from '../../../../common/utils';
import TooltipIconButton from '../../../common-components/TooltipIconButton';
import { RequiredFieldSymbol } from '../../../common-components/RequiredFieldSymbol';
import config from '../../../../resources/config.json';
import BaseFormFields from '../../../common-components/BaseFormFields';
import errorsTexts from '../../../../resources/errorTexts.json';
import { ModalError } from '../../../common-components/ModalWindow';

const uiTexts = require('../../../../resources/uiTexts.json');

const splitB2BPaymentsConfigurationTexts = getConfigSection(
  uiTexts,
  `merchants.channels.form.fields.splitB2BPaymentsConfiguration`,
);
const confirmationTexts = getConfigSection(uiTexts, 'common.table.dialogs.deleteItem');

export default class DestinationsAccountsTable extends React.PureComponent {
  static propTypes = {
    tableRowTemplate: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        function: PropTypes.func.isRequired,
        width: PropTypes.string,
      }),
    ),
    textsKey: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    initialData: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    validationSchema: PropTypes.object.isRequired,
    dispatchFormData: PropTypes.func.isRequired,
    setSubmittingDetails: PropTypes.func.isRequired,
    isFormEditable: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSubmitNewRow = this.handleSubmitNewRow.bind(this);
    this.updateFormState = this.updateFormState.bind(this);

    const { initialData } = this.props;

    const mappedData = initialData.map(destinationAccountData => {
      return {
        tableRowKey: uuid(),
        data: destinationAccountData,
        isActive: true,
      };
    });

    this.state = {
      tableData: mappedData,
      modalState: {
        isVisible: false,
      },
    };
  }

  render() {
    const { tableRowTemplate, textsKey, validationSchema, isFormEditable } = this.props;
    const { tableData, modalState } = this.state;

    const columnTexts = getConfigSection(
      splitB2BPaymentsConfigurationTexts,
      `destinationAccountsConfiguration.tableColumns`,
    );
    const tooltipTexts = getConfigSection(uiTexts, 'common.table.tooltips');
    const icons = getConfigSection(config, 'ui.common.table.icons');

    const ColumnInput = columnData => {
      return BaseFormFields.renderCommonFramework({
        id: columnData.id,
        label: '',
        renderFieldComponent: columnData.function,
        isNotRenderValidation: false,
        testIdPrefix: 'destination-form',
      });
    };

    return (
      <div data-testid="destination-accounts-table-container">
        {modalState.isVisible && this.renderModal(modalState)}
        <div className="c-form-section__content">
          <div className="u-mb">
            <Heading className="u-mb" headerType={4} testId="table-header">
              <div>
                {getConfigSection(splitB2BPaymentsConfigurationTexts, `destinationAccountsConfiguration.title`)}
              </div>
            </Heading>
            <Layout center>
              <Column span="1/1">
                <Formik
                  validationSchema={validationSchema}
                  onSubmit={this.handleSubmitNewRow}
                  initialValues={{
                    merchantReference: '',
                    merchantProgramCode: '',
                    merchantAccno: '',
                    merchantCurrency: '',
                  }}
                >
                  {formikProps => {
                    this.updateFormState(formikProps);
                    return (
                      <Form>
                        <Table>
                          <Table.Thead>
                            <Table.Tr data-testid="table-columns-row">
                              <Table.Th />
                              {tableRowTemplate.map(columnData => {
                                const columnTestId = `${columnData.id}-column`;

                                return (
                                  <Table.Th
                                    key={`${textsKey}-table-head-column-${columnData.id}`}
                                    style={{
                                      width: columnData.width || 'auto',
                                    }}
                                    data-testid={columnTestId}
                                  >
                                    <div id={columnData.id}>
                                      {columnData.useRequiredLabel ? <RequiredFieldSymbol /> : ''}
                                      {getConfigSection(columnTexts, columnData.id)}
                                      <span className="u-m-xxsmall" />
                                    </div>
                                  </Table.Th>
                                );
                              })}
                            </Table.Tr>
                          </Table.Thead>
                          <Table.Tbody>
                            {tableData.map(row => {
                              const id = `${textsKey}-table-body-row-${row.tableRowKey}`;

                              return (
                                <Table.Tr
                                  key={id}
                                  id={id}
                                  onMouseOver={this.highlightRow(id)}
                                  onFocus={this.highlightRow(id)}
                                  onMouseLeave={this.unHighlightRow(id)}
                                  style={{
                                    color: row.isActive || '#ff4963',
                                  }}
                                  data-testid="table-row"
                                >
                                  <Table.Td
                                    style={{ width: '1px' }}
                                    key={`${textsKey}-table-delete-button-${row.tableRowKey}`}
                                  >
                                    {row.isActive ? (
                                      <TooltipIconButton
                                        tooltipText={tooltipTexts.deleteDataItem}
                                        disabled={!isFormEditable}
                                        testId="delete-item-button"
                                        buttonProps={{
                                          className: 'c-table__btn--border',
                                          secondary: true,
                                          small: true,
                                          icon: icons.deleteDataItem,
                                          onClick: this.handleDeleteDataItem(row),
                                        }}
                                      />
                                    ) : (
                                      <TooltipIconButton
                                        tooltipText={tooltipTexts.restoreItem}
                                        disabled={!isFormEditable}
                                        testId="restore-item-button"
                                        buttonProps={{
                                          className: 'c-table__btn--border',
                                          secondary: true,
                                          small: true,
                                          icon: icons.reload,
                                          onClick: this.handleRecoverDataItem(row),
                                        }}
                                      />
                                    )}
                                  </Table.Td>
                                  {tableRowTemplate.map(columnData => {
                                    const cellTestId = `${columnData.id}-cell`;

                                    return (
                                      <Table.Td key={`${textsKey}-column-${columnData.id}`} data-testid={cellTestId}>
                                        {row.data[columnData.id]}
                                      </Table.Td>
                                    );
                                  })}
                                </Table.Tr>
                              );
                            })}
                            <Table.Tr key="inputs" id="inputs-id">
                              <Table.Td style={{ verticalAlign: 'middle', height: 110 }}>
                                <TooltipIconButton
                                  tooltipText={tooltipTexts.addDataItem}
                                  disabled={!isFormEditable}
                                  testId="add-item-button"
                                  buttonProps={{
                                    onClick: () => {},
                                    secondary: true,
                                    small: true,
                                    round: true,
                                    icon: icons.addDataItem,
                                    type: 'submit',
                                  }}
                                />
                              </Table.Td>
                              {tableRowTemplate.map(columnData => {
                                return (
                                  <Table.Td
                                    key={`${textsKey}-input-${columnData.id}`}
                                    style={{
                                      verticalAlign: 'top',
                                      height: 110,
                                    }}
                                  >
                                    {ColumnInput(columnData)}
                                  </Table.Td>
                                );
                              })}
                            </Table.Tr>
                          </Table.Tbody>
                        </Table>
                      </Form>
                    );
                  }}
                </Formik>
              </Column>
            </Layout>
          </div>
        </div>
      </div>
    );
  }

  highlightRow = id => () => {
    document.getElementById(id).style.backgroundColor = 'lightGray';
  };

  unHighlightRow = id => () => {
    document.getElementById(id).style.backgroundColor = 'white';
  };

  updateFormState(formikProps) {
    const { setSubmittingDetails } = this.props;
    if (formikProps.dirty) {
      setSubmittingDetails({
        isFormDirty: true,
        isNeedActionPrevent: true,
      });
    } else {
      setSubmittingDetails({
        isFormDirty: false,
        isNeedActionPrevent: false,
      });
    }
  }

  updateInitialData(tableData) {
    const { dispatchFormData } = this.props;
    const destinationAccounts = tableData.map(rowData => rowData.data);
    dispatchFormData('paymentSplitConfiguration.destinationAccounts', destinationAccounts);
  }

  // eslint-disable-next-line class-methods-use-this
  updateTableDataWithChangedRow(data, currentRow, options) {
    return data.map(rowItem => {
      if (rowItem.tableRowKey === currentRow.tableRowKey) {
        return { ...rowItem, ...options };
      }
      return rowItem;
    });
  }

  filterInactiveItems = data => {
    return data.filter(item => item.isActive === true);
  };

  handleDeleteDataItem = currentRow => () => {
    this.setState(prevState => {
      const { tableData } = prevState;

      const updatedTableData = this.updateTableDataWithChangedRow(tableData, currentRow, {
        isActive: false,
      });

      const withoutInactive = this.filterInactiveItems(updatedTableData);
      this.updateInitialData(withoutInactive);
      return {
        tableData: [...updatedTableData],
      };
    });
  };

  handleRecoverDataItem = currentRow => () => {
    const duplicates = this.checkForDuplicates(currentRow.data);
    if (duplicates) {
      const { buttonOk } = confirmationTexts;
      const modalTitle = getConfigSection(
        splitB2BPaymentsConfigurationTexts,
        `destinationAccountsConfiguration.modalTitle`,
      );
      this.changeModalState({
        isVisible: true,
        buttonConfirmText: buttonOk,
        errorKey: 'validation.recoverDuplicateMessage',
        title: modalTitle,
      });
      return;
    }
    this.setState(prevState => {
      const { tableData } = prevState;

      const updatedTableData = this.updateTableDataWithChangedRow(tableData, currentRow, {
        isActive: true,
      });
      const withoutInactive = this.filterInactiveItems(updatedTableData);
      this.updateInitialData(withoutInactive);
      return {
        tableData: [...updatedTableData],
      };
    });
  };

  checkForDuplicates({ merchantReference, merchantCurrency }) {
    const { tableData } = this.state;
    return tableData.find(
      dataRow =>
        dataRow.data.merchantReference === merchantReference &&
        dataRow.data.merchantCurrency === merchantCurrency &&
        dataRow.isActive === true,
    );
  }

  handleSubmitNewRow(values, { resetForm, setErrors }) {
    const { tableData } = this.state;
    const duplicateRow = this.checkForDuplicates(values);
    if (duplicateRow) {
      const errorMessage = getConfigSection(errorsTexts, 'validation.duplicate');
      setErrors({ merchantReference: errorMessage });
      return;
    }
    this.setState(() => {
      const withNewRowData = [
        ...tableData,
        {
          tableRowKey: uuid(),
          data: { ...values },
          isActive: true,
        },
      ];
      const withoutInactive = this.filterInactiveItems(withNewRowData);
      this.updateInitialData(withoutInactive);
      return { tableData: withNewRowData };
    });
    resetForm();
  }

  changeModalState({ isVisible, buttonConfirmText, errorKey, title }) {
    this.setState({
      modalState: { isVisible, buttonConfirmText, errorKey, title },
    });
  }

  renderModal({ buttonConfirmText, errorKey, title }) {
    return (
      <ModalError
        title={title}
        buttonConfirmText={buttonConfirmText}
        onConfirm={() => this.changeModalState(false)}
        errorKey={errorKey}
      />
    );
  }
}
