import React from 'react';
import * as Yup from 'yup';
import { uniqueId } from '@turbopay/ts-helpers/object-utils';
import arrayMove from 'array-move';
import moment from 'moment';
import BaseFormFields from '../../common-components/BaseFormFields';
import commonPropTypes from '../../../common/common-prop-types';
import { getConfigSection, getSecondsFromTime, pipeOr, pipeAnd } from '../../../common/utils';
import { addDefaultSelectOptionToSelectValues } from '../../common-components/common-functions';
import {
  NOTIFICATIONS_AUTHENTICATION_TYPES,
  CONTENT_TYPES,
  REGEX,
  REQUEST_TYPES,
  RETRY_SCHEME_TYPES,
  TYPES,
  AUTHENTICATION_TYPES,
} from '../../../common/constants';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';
import InfoIconText from '../../common-components/InfoIconText';

const uiTexts = require('../../../resources/uiTexts.json');

const errorsTexts = require('../../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

const validationTexts = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static isAuthentication = authType =>
    authType === AUTHENTICATION_TYPES.BASIC || authType === AUTHENTICATION_TYPES.OAUTH;

  static validationSchema() {
    return Yup.object().shape({
      notificationPolicy: Yup.object({
        isActiveNotifications: Yup.bool(),
        authType: Yup.string().when('isActiveNotifications', {
          is: true,
          then: Yup.string().required(error.mandatoryField),
        }),
        username: Yup.string()
          .when(['isActiveNotifications', 'authType'], {
            is: (isActiveNotifications, authType) => isActiveNotifications && authType === AUTHENTICATION_TYPES.BASIC,
            then: Yup.string().required(error.mandatoryField),
          })
          .max(100, validationTexts.maxLength.replace('$MAX$', 100)),
        password: Yup.string()
          .when(['isActiveNotifications', 'authType'], {
            is: (isActiveNotifications, authType) => isActiveNotifications && authType === AUTHENTICATION_TYPES.BASIC,
            then: Yup.string().required(error.mandatoryField),
          })
          .max(100, validationTexts.maxLength.replace('$MAX$', 100)),
        clientId: Yup.string()
          .when(['isActiveNotifications', 'authType'], {
            is: (isActiveNotifications, authType) => isActiveNotifications && authType === AUTHENTICATION_TYPES.OAUTH,
            then: Yup.string().required(error.mandatoryField),
          })
          .max(100, validationTexts.maxLength.replace('$MAX$', 100)),
        clientSecret: Yup.string()
          .when(['isActiveNotifications', 'authType'], {
            is: (isActiveNotifications, authType) => isActiveNotifications && authType === AUTHENTICATION_TYPES.OAUTH,
            then: Yup.string().required(error.mandatoryField),
          })
          .max(100, validationTexts.maxLength.replace('$MAX$', 100)),
        url: Yup.string().when('isActiveNotifications', {
          is: true,
          then: Yup.string()
            .required(error.mandatoryField)
            .url(error.url)
            .max(512, validationTexts.maxLength.replace('$MAX$', 512)),
        }),
        oauthUrl: Yup.string().when(['isActiveNotifications', 'authType'], {
          is: (isActiveNotifications, authType) => isActiveNotifications && authType === AUTHENTICATION_TYPES.OAUTH,
          then: Yup.string()
            .required(error.mandatoryField)
            .url(error.url)
            .max(100, validationTexts.maxLength.replace('$MAX$', 100)),
        }),
        contentType: Yup.string().when('isActiveNotifications', {
          is: true,
          then: Yup.string().required(error.mandatoryField),
        }),
        requestType: Yup.string().when('isActiveNotifications', {
          is: true,
          then: Yup.string().required(error.mandatoryField),
        }),
        retryPolicy: Yup.object().when('isActiveNotifications', {
          is: true,
          then: Yup.object({
            isRetryActive: Yup.bool(),
            retrySchema: Yup.string().when('isRetryActive', {
              is: true,
              then: Yup.string().required(error.mandatoryField),
            }),
            intervalsConfiguration: Yup.array().when(['isRetryActive', 'retrySchema'], {
              is: (isRetryActive, retrySchema) => isRetryActive && retrySchema === TYPES.AT_SPECIFIC_INTERVALS,
              then: Yup.array()
                .of(
                  Yup.object({
                    retryNumber: Yup.number(),
                    intervalBetweenRetriesSec: Yup.number(),
                  }),
                )
                .default([])
                .test('length > 0', 'At least 1 row must be added', value => value.length > 0)
                .test(
                  'values >= 00:00:01',
                  'All times must be >= 00:00:01',
                  value => !value.find(v => v.intervalBetweenRetriesSec === 0),
                ),
            }),
            timeConfiguration: Yup.object().when(['isRetryActive', 'retrySchema'], {
              is: (isRetryActive, retrySchema) => isRetryActive && retrySchema === TYPES.AT_SPECIFIC_TIME,
              then: Yup.object({
                numberOfRetries: Yup.number()
                  .max(99, validationTexts.integerSmallerThan.replace('$MAX$', 100))
                  .min(1, validationTexts.integerGreaterThan.replace('$MIN$', 0))
                  .required(error.mandatoryField),
                retryTime: Yup.string()
                  .test('>=00:00:01', 'Minimum allowed value is 00:00:01', value => {
                    const timeObject = moment(value, 'HH:mm:ss');
                    const seconds = timeObject.seconds();
                    const minutes = timeObject.minutes();
                    const hours = timeObject.hours();
                    const result = seconds + minutes * 60 + hours * 3600;

                    return result !== 0;
                  })
                  .matches(REGEX.TIME_FORMAT, error.timeFormatError),
              }),
            }),
          }),
        }),
      }),
      billingEventConfiguration: Yup.object({
        numberOfDaysBefore: Yup.number()
          .max(99, validationTexts.integerSmallerThan.replace('$MAX$', 100))
          .min(0, validationTexts.positiveNumber)
          .required(`${error.mandatoryField}. ${error.skipOption.replace('$OPTION$', 0)}`),
      }),
    });
  }

  constructor(props) {
    super(props);

    this.getSpecificIntervals = values => {
      let specificIntervals = values?.notificationPolicy?.retryPolicy?.intervalsConfiguration || [];
      if (!Array.isArray(specificIntervals)) {
        specificIntervals = [];
      }
      return specificIntervals;
    };

    this.onSortEnd = ({ oldIndex, newIndex }) => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      const { values } = formikProps;
      const specificIntervals = this.getSpecificIntervals(values);

      const specificIntervalsOrdered = arrayMove(specificIntervals, oldIndex, newIndex);

      setFieldValue('notificationPolicy.retryPolicy.intervalsConfiguration', specificIntervalsOrdered);
    };

    this.addNewLine = () => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      const { values } = formikProps;
      const specificIntervals = this.getSpecificIntervals(values);
      const itemsCopy = [...specificIntervals];
      const nextIndex = itemsCopy.length;
      itemsCopy.push({
        retryNumber: nextIndex + 1,
        intervalBetweenRetriesSec: 0,
        id: uniqueId(),
      });
      setFieldValue('notificationPolicy.retryPolicy.intervalsConfiguration', itemsCopy);
    };

    const itemToChange = {};

    this.handleChange = (value, id) => {
      itemToChange.value = value;
      itemToChange.id = id;
    };

    this.handleBlur = () => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      const { values } = formikProps;
      const specificIntervals = this.getSpecificIntervals(values);
      const itemsCopy = [...specificIntervals];
      const item = itemsCopy.find(i => i.id === itemToChange.id);
      if (item) {
        item.intervalBetweenRetriesSec = itemToChange.value;
        setFieldValue('notificationPolicy.retryPolicy.intervalsConfiguration', itemsCopy);
      }
    };

    this.deleteRow = (e, id) => {
      e.preventDefault();
      e.stopPropagation();
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      const { values } = formikProps;
      const specificIntervals = this.getSpecificIntervals(values);
      const itemsCopy = [...specificIntervals];
      const indexOfItem = itemsCopy.findIndex(i => i.id === id);
      itemsCopy.splice(indexOfItem, 1);
      setFieldValue('notificationPolicy.retryPolicy.intervalsConfiguration', itemsCopy);
    };
  }

  render() {
    const { textsKey, authToken, isFormEditable, isUpdateOperation, formikProps } = this.props;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const authType = formikProps.values?.notificationPolicy?.authType;
    const { renderGenericInput } = BaseFormFields;

    /**
     * "Required" label predicates
     */
    const isPolicyEnabled = formValues => {
      const isEnabled = formValues.notificationPolicy.isActiveNotifications;
      return isEnabled;
    };

    const isBasicAuth = formValues => {
      const authType = formValues.notificationPolicy.authType;
      return authType === 'BASIC';
    };

    const isOauth = formValues => {
      const authType = formValues.notificationPolicy.authType;
      return authType === 'OAUTH';
    };

    const isRetryEnabled = formValues => {
      const isRetryActive = formValues.notificationPolicy.retryPolicy.isRetryActive;
      return isRetryActive;
    };

    const isRetryAtTime = formValues => {
      const retrySchema = formValues.notificationPolicy.retryPolicy.retrySchema;
      return retrySchema === 'SPECIFIC_TIME';
    };

    const isRetryAtIntervals = formValues => {
      const retrySchema = formValues.notificationPolicy.retryPolicy.retrySchema;
      return retrySchema === 'SPECIFIC_INTERVALS';
    };

    const fieldsRenderConfig = [
      {
        id: 'notificationPolicy',
        subItems: [
          {
            id: 'isActiveNotifications',
            function: this.renderServiceActiveCheckbox.bind(this),
          },
          {
            id: 'authType',
            useRequiredLabel: isPolicyEnabled,
            function: this.renderAuthenticationTypeDropdown.bind(this),
          },
          {
            id: authType === AUTHENTICATION_TYPES.OAUTH ? 'clientId' : 'username',
            useRequiredLabel: pipeAnd(isPolicyEnabled, pipeOr(isBasicAuth, isOauth)),
            function: this.renderTextAuthenticationInput.bind(this),
            labelAddOn: isUpdateOperation && (
              <InfoIconText
                text={getConfigSection(uiTexts, 'merchants.form.fields.credentials.groupInfo')}
                testId={`${authType === AUTHENTICATION_TYPES.OAUTH ? 'clientId' : 'username'}-info-text`}
              />
            ),
          },
          {
            id: authType === AUTHENTICATION_TYPES.OAUTH ? 'clientSecret' : 'password',
            useRequiredLabel: pipeAnd(isPolicyEnabled, pipeOr(isBasicAuth, isOauth)),
            function: this.renderTextAuthenticationInput.bind(this),
          },
          {
            id: 'oauthUrl',
            useRequiredLabel: pipeAnd(isPolicyEnabled, isOauth),
            function: this.renderTextOAUTHInput.bind(this),
          },
          {
            id: 'url',
            useRequiredLabel: isPolicyEnabled,
            function: this.renderTextInput.bind(this),
          },
          {
            id: 'contentType',
            useRequiredLabel: isPolicyEnabled,
            function: this.renderContentTypeDropdown.bind(this),
          },
          {
            id: 'requestType',
            useRequiredLabel: isPolicyEnabled,
            function: this.renderRequestTypeDropdown.bind(this),
          },
          {
            id: 'retryPolicy',
            subItems: [
              {
                id: 'isRetryActive',
                function: this.renderRetryActiveCheckbox.bind(this),
              },
              {
                id: 'retrySchema',
                useRequiredLabel: isRetryEnabled,
                function: this.renderRetrySchemaTypeDropdown.bind(this),
              },
              {
                id: 'timeConfiguration',
                subItems: [
                  {
                    id: 'group',
                    function: () => {},
                  },
                  {
                    id: 'numberOfRetries',
                    useRequiredLabel: isRetryAtTime,
                    function: this.renderSpecificTimeNumber.bind(this),
                  },
                  {
                    id: 'retryTime',
                    useRequiredLabel: isRetryAtTime,
                    function: this.renderSpecificTime.bind(this),
                  },
                ],
              },
              {
                id: 'intervalsConfiguration',
                subItems: [
                  {
                    id: 'specificIntervals',
                    useRequiredLabel: isRetryAtIntervals,
                    function: this.renderSpecificIntervalsTable.bind(this),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'billingEventConfiguration',
        subItems: [
          {
            id: 'numberOfDaysBefore',
            function: renderGenericInput('number', isFormEditable),
          },
        ],
      },
    ];

    return (
      <>
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="notification-settings-form"
        />
      </>
    );
  }

  renderTextInput(props) {
    const { formikProps, isFormEditable } = this.props;
    const { renderGenericInput } = BaseFormFields;
    const { values } = formikProps;
    const enabled = isFormEditable && (values?.notificationPolicy?.isActiveNotifications || false);

    return renderGenericInput('text', enabled)(props);
  }

  renderSpecificTime(props) {
    const { formikProps, isFormEditable } = this.props;
    const { renderGenericDurationPicker } = BaseFormFields;
    const { values } = formikProps;
    const retrySchemaType = values?.notificationPolicy?.retryPolicy?.retrySchema;
    const enabled =
      isFormEditable &&
      (values?.notificationPolicy?.isActiveNotifications || false) &&
      (values?.notificationPolicy?.retryPolicy?.isRetryActive || false) &&
      retrySchemaType === TYPES.AT_SPECIFIC_TIME;

    return renderGenericDurationPicker(enabled, '1')({ ...props, formikProps, value: getSecondsFromTime(props.value) });
  }

  renderSpecificTimeNumber(props) {
    const { formikProps, isFormEditable } = this.props;
    const { renderGenericInput } = BaseFormFields;
    const { values } = formikProps;
    const retrySchemaType = values?.notificationPolicy?.retryPolicy?.retrySchema;
    const enabled =
      isFormEditable &&
      (values?.notificationPolicy?.isActiveNotifications || false) &&
      (values?.notificationPolicy?.retryPolicy?.isRetryActive || false) &&
      retrySchemaType === TYPES.AT_SPECIFIC_TIME;

    return renderGenericInput('number', enabled)(props);
  }

  renderTextAuthenticationInput(props) {
    const { formikProps, isFormEditable } = this.props;
    const { renderGenericInput } = BaseFormFields;
    const { values } = formikProps;
    const authType = values?.notificationPolicy?.authType;
    const enabled =
      isFormEditable &&
      (values?.notificationPolicy?.isActiveNotifications || false) &&
      FormFields.isAuthentication(authType);
    return renderGenericInput('text', enabled)(props);
  }

  renderTextOAUTHInput(props) {
    const { formikProps, isFormEditable } = this.props;
    const { renderGenericInput } = BaseFormFields;
    const { values } = formikProps;
    const authType = values?.notificationPolicy?.authType;
    const enabled =
      isFormEditable &&
      (values?.notificationPolicy?.isActiveNotifications || false) &&
      authType === AUTHENTICATION_TYPES.OAUTH;

    return renderGenericInput('text', enabled)(props);
  }

  renderServiceActiveCheckbox(props) {
    const { isFormEditable } = this.props;
    const { renderGenericCheckbox } = BaseFormFields;
    const checkboxEnabledText = getConfigSection(uiTexts, 'common.editForm.checkboxEnable');

    return renderGenericCheckbox(isFormEditable, checkboxEnabledText, {
      onChange: this.handleServiceActiveCheckboxChange(props.value),
    })(props);
  }

  handleServiceActiveCheckboxChange(isEnabled) {
    return () => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      setFieldValue('notificationPolicy.isActiveNotifications', !isEnabled);
    };
  }

  renderRetryActiveCheckbox(props) {
    const { formikProps, isFormEditable } = this.props;
    const { renderGenericCheckbox } = BaseFormFields;
    const checkboxEnabledText = getConfigSection(uiTexts, 'common.editForm.checkboxEnable');
    const { values } = formikProps;
    const enabled = isFormEditable && (values?.notificationPolicy?.isActiveNotifications || false);

    return renderGenericCheckbox(enabled, checkboxEnabledText, {
      onChange: this.handleRetryActiveCheckboxChange(props.value),
    })(props);
  }

  handleRetryActiveCheckboxChange(isEnabled) {
    return () => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      setFieldValue('notificationPolicy.retryPolicy.isRetryActive', !isEnabled);
    };
  }

  renderAuthorizationRetryActiveCheckbox(props) {
    const { isFormEditable } = this.props;
    const { renderGenericCheckbox } = BaseFormFields;
    const checkboxEnabledText = getConfigSection(uiTexts, 'common.editForm.checkboxEnable');

    return renderGenericCheckbox(isFormEditable, checkboxEnabledText, {
      onChange: this.handleAuthorizationRetryActiveCheckboxChange(props.value),
    })(props);
  }

  renderAuthenticationTypeDropdown(props) {
    const { formikProps, isFormEditable, textsKey } = this.props;
    const { values } = formikProps;
    const { renderGenericSelect } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, `${textsKey}.form.placeholders.authType`);
    const enabled = isFormEditable && (values?.notificationPolicy?.isActiveNotifications || false);
    return renderGenericSelect(
      enabled,
      addDefaultSelectOptionToSelectValues(
        NOTIFICATIONS_AUTHENTICATION_TYPES.map(item => ({
          value: item,
          label: getConfigSection(uiTexts, `${textsKey}.form.types.${item.toLowerCase()}`) || '',
        })),
        '',
        selectAnOptionText,
      ),
    )(props);
  }

  renderContentTypeDropdown(props) {
    const { formikProps, isFormEditable, textsKey } = this.props;
    const { values } = formikProps;
    const { renderGenericSelect } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, `${textsKey}.form.placeholders.contentType`);
    const enabled = isFormEditable && (values?.notificationPolicy?.isActiveNotifications || false);

    return renderGenericSelect(
      enabled,
      addDefaultSelectOptionToSelectValues(
        CONTENT_TYPES.map(item => ({
          value: item,
          label: getConfigSection(uiTexts, `${textsKey}.form.types.${item.toLowerCase()}`) || [],
        })),
        '',
        selectAnOptionText,
      ),
    )(props);
  }

  renderRequestTypeDropdown(props) {
    const { formikProps, isFormEditable, textsKey } = this.props;
    const { values } = formikProps;
    const { renderGenericSelect } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, `${textsKey}.form.placeholders.requestType`);
    const enabled = isFormEditable && (values?.notificationPolicy?.isActiveNotifications || false);

    return renderGenericSelect(
      enabled,
      addDefaultSelectOptionToSelectValues(
        REQUEST_TYPES.map(item => ({
          value: item,
          label: getConfigSection(uiTexts, `${textsKey}.form.types.${item.toLowerCase()}`) || [],
        })),
        '',
        selectAnOptionText,
      ),
    )(props);
  }

  renderRetrySchemaTypeDropdown(props) {
    const { formikProps, isFormEditable, textsKey } = this.props;
    const { values } = formikProps;
    const { renderGenericSelect } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, `${textsKey}.form.placeholders.retrySchemaType`);
    const enabled =
      isFormEditable &&
      (values?.notificationPolicy?.isActiveNotifications || false) &&
      (values?.notificationPolicy?.retryPolicy?.isRetryActive || false);

    return renderGenericSelect(
      enabled,
      addDefaultSelectOptionToSelectValues(
        RETRY_SCHEME_TYPES.map(item => ({
          value: item,
          label: getConfigSection(uiTexts, `${textsKey}.form.types.${item.toLowerCase()}`) || [],
        })),
        '',
        selectAnOptionText,
      ),
    )(props);
  }

  renderSpecificIntervalsTable(props) {
    const { formikProps, isFormEditable, textsKey } = this.props;
    const { values } = formikProps;
    const headers = [
      '',
      getConfigSection(uiTexts, `${textsKey}.form.headers.retryNumber`),
      getConfigSection(uiTexts, `${textsKey}.form.headers.intervalBetween`),
    ];
    const retrySchemaType = values?.notificationPolicy?.retryPolicy?.retrySchema;
    const enabled =
      isFormEditable &&
      (values?.notificationPolicy?.isActiveNotifications || false) &&
      (values?.notificationPolicy?.retryPolicy?.isRetryActive || false) &&
      retrySchemaType === TYPES.AT_SPECIFIC_INTERVALS;
    const { renderDurationTable } = BaseFormFields;
    const specificIntervals = this.getSpecificIntervals(values);
    let isDisabledAddButton = false;
    if (specificIntervals.find(i => i.intervalBetweenRetriesSec === 0)) {
      isDisabledAddButton = true;
    }

    return renderDurationTable(
      specificIntervals,
      this.onSortEnd,
      this.addNewLine,
      this.deleteRow,
      this.handleChange,
      this.handleBlur,
      enabled,
      headers,
      'notificationPolicy.retryPolicy.intervalsConfiguration',
      isDisabledAddButton,
    )(props);
  }
}
